html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

body { display: flex;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

#root { flex: 1}
.scrollable { overflow-y: scroll; }
/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;

  transition: opacity 400ms ease-out;
}
.fade-exit {
  opacity: 1;

}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-out;
}

/** CUST=OM ANIMATION */
@keyframes swipeRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0.3;
		transform: translateX(25px);
	}
}

@keyframes skidde {
	0% {
		transform: translateX(0);
	}

  25% {
		transform: translateX(25px);
	}

  75% {
    transform: translateX(-25px);
  }
  
	100% {
    transform: translateX(0);
	}
}

@keyframes rotate {
	from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@keyframes timer {
	0% {
		transform: scale(1);
	}

  50% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes readySetGo {
	0% {
		transform: scale(0.75);
    opacity: 1
	}

  75% {
    opacity: 1
  }

	100% {
		transform: scale(1.2);
    opacity: 0.5;
	}
}

.amboy {
  font-family: "amboy-black", sans-serif;
font-weight: 400;
font-style: normal;
}


@keyframes spin {
  to { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { transform: rotate(360deg); }
}

